import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Input } from 'antd';
import { Ico } from './Uikit';
import { Context } from '../context';
import { PreloaderGif } from './Preloader';

const { TextArea } = Input;

export function TranslateLine({ track = {}, editing, setEditing }) {
  const context = useContext(Context);

  const [captions, setCaptions] = useState(track.captions);
  const [captionsRus, setCaptionsRus] = useState(track.captions_rus);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (editing === track.id && textAreaRef.current) {
      textAreaRef.current.focus();
      try {
        textAreaRef.current.resizableTextArea.textArea.setSelectionRange(captions.length, captions.length);
      } catch (e) { }
    }
  }, [editing, track.id]);

  const formatTime = (t) => {
    t = t.split('.')[0];
    const parts = t.split(':');
    if (parts[0] === '0') parts.shift();
    return parts.join(':');
  };

  const resetCaptions = () => {
    setCaptions(track.captions)
  }
  const saveCaptions = (e) => {
    e.stopPropagation();
    context.handles.saveCaptionsTranslate(track, captions);
    setEditing('');
    if (textAreaRef.current) {
      textAreaRef.current.blur();
    }
  }


  const handleResetAndToggle = (e) => {
    e.stopPropagation()
    if (typeof context.state.playStart === 'number' && context.state.playingFragment === track.id) {
      // wavesurfer.seekTo(0);
    }
  };

  return (
    <div
      onClick={() => setEditing(track.id)}
      className={`xt_line ${editing === track.id ? 'active' : ''}`}
    >
      <div className="xt_time">

        <div className="xt_fragment_play0" onClick={handleResetAndToggle}>
          <div className="x_player_control_play" onClick={() => {
            setEditing(track.id)
            if (context.state['mp3']) {

              // context.handles.setPlaybackTime(track['start'])
              context.handles.setPlayStart(track['start'])
              // context.handles.setPlayOriginalStart(track['original_start']);
              if (context.state['playing'] && context.state.playingFragment === track.id) {
                context.handles.setPlaying(false)
                context.handles.setPlayingFragment()
              }

              if (!context.state['playing'] || context.state.playingFragment !== track.id) {
                context.handles.setPlaying(true)
                context.handles.setPlayingFragment(track.id)
                // context.handles.setPlaybackTime(track['start'])
                // context.handles.setPlayOriginalStart(track['original_start']);
              }
              // context.handles.setPlaying(!context.state['playing'])
            }
          }
          }>
            {context.state['mp3'] && <div className="ico ico--40">
              {context.state.playingFragment === track.id && <>
                {!context['state']['playing'] && <div>play_arrow</div>}
                {context['state']['playing'] && <div>pause</div>}
              </>}

              {context.state.playingFragment !== track.id && <>
                <div>play_arrow</div>
              </>}


            </div>}
            {!context.state['mp3'] && <PreloaderGif />}
          </div>
        </div>

        <div className="xt_time_1">{formatTime(track.start_rus)}</div>
        <div className="xt_time_1">–</div>
        <div className="xt_time_1">{formatTime(track.end_rus)}</div>
      </div>

      <div className="xt_text">{captionsRus}</div>

      <div className={`xt_text ${context.state.changedRecently.includes(track.id) ? 'xt_text--justchanged' : ''} ${context.state.changedTranslation.includes(track.id) ? 'xt_text--changed' : ''}`}>
        {captions}

        {editing === track.id &&
          <div className="xt_textarea0">
            <TextArea
              ref={textAreaRef} // Attach the ref to the TextArea
              value={captions}
              rows={3}
              className="xt_textarea"
              placeholder="Translation"
              onChange={(e) => setCaptions(e.target.value)}
              // style={{ width: 'calc(100% + 2em)', maxWidth: 'none' }}
            />

            {captions !== track.captions && <div className="xt_textarea_actions">
              <div className={`x_save ${captions === track.captions ? 'x_save--saved' : ' x_save--cancel'}`} onClick={resetCaptions}><Ico size={'1em'}>undo</Ico></div>
              <div className={`x_save ${captions === track.captions ? 'x_save--saved' : ''}`} onClick={saveCaptions}><Ico size={'1em'}>check</Ico></div>
            </div>}

          </div>}
      </div>

      {editing === track.id && <div className="xt_line_bg active" />}
    </div>
  );
}