import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';
import { PlayerTranslated } from './PlayerTranslated';
import { PopPlayer } from './PopPlayer';
import { HorizontalSlider } from './HorizontalSlider';
import { Select } from './Uikit_Select';

const API_URL = process.env.REACT_APP_API_URL;

export function Pop({ oneTrackId, handleClose, updateTrack }) {
  const context = useContext(Context);


  const [oneTrack, setOneTrack] = useState()
  const getOneTrack = (id) => {
    return context.state.translationData.filter(one => one['id'] === oneTrackId)[0]
  };

  const [shown1, setShown1] = useState(false)
  const [shown2, setShown2] = useState(false)
  const [captions, setCaptions] = useState([])
  const [voice, setVoice] = useState()
  const [speed, setSpeed] = useState()
  const [background, setBackground] = useState(false)

  useEffect(() => {
    if (context.state.translationData.length) {
      let oneTrack = getOneTrack(oneTrackId) || {};
      setOneTrack(oneTrack)
      setCaptions([oneTrack['captions']])
      setVoice([oneTrack['voice']])
      setSpeed([oneTrack['speed']])
      setBackground([oneTrack['add_background']])
    }
  }, [context.state.translationData, oneTrackId])

  useEffect(() => {
    console.log('background = ', background);
  }, [background])

  useEffect(() => {
    setChanged(false)
  }, [oneTrackId])



  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (
      oneTrack && (
        oneTrack['voice'] !== voice[0] ||
        oneTrack['speed'] !== speed[0] ||
        oneTrack['add_background'] !== background[0] ||
        oneTrack['captions'] !== captions[0])
    ) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }, [speed, voice, captions, oneTrackId])



  const cols = 79;

  useEffect(() => {
    setShown1(true)
    setTimeout(() => {
      setShown2(true)
      // context.handles.setPopOpen(true)
    }, 5);

  }, [])



  const handleReset = () => {
    setCaptions([oneTrack['captions']])
    setVoice([oneTrack['voice']])
    setSpeed([oneTrack['speed']])
    setBackground([oneTrack['add_background']])
    setChanged(false)
  }

  const handleClosePop = () => {
    setShown2(false)
    setTimeout(() => {
      setShown1(false)
      handleClose(false)
      context.handles.setPop()
    }, 5);
  }

  const removeEdgeLineBreaks = (text) => {
    return text ? text.replace(/^\s*[\r\n]+|[\r\n]+\s*$/g, '') : '';
  };


  const handleSubmit = () => {
    if (captions.length === 1) {
      if (removeEdgeLineBreaks(captions[0]) !== oneTrack['captions']
        || voice !== oneTrack['voice']
        || voice !== oneTrack['speed']
      ) {
        // const newData = {
        //   captions: captions.map(one => removeEdgeLineBreaks(one)),
        //   voice: voice,
        //   speed: speed
        // };
        // console.log('newData = ', newData);
        // context.handles.addNewChange(oneTrack['id'])
        // updateTrack(oneTrack['id'], newData)
        context.handles.saveCaptionsBreak(oneTrack, captions[0], voice[0], speed[0], background[0])
      }

    } else {
      // alert('not yet implemented')
      context.handles.saveCaptionsBreak(oneTrack, captions, voice, speed, background)
    }
  }


  const validate = (val) => {
    // Remove potentially malicious characters
    // let cleanedValue = val.replace(/[<>&"]/g, '');
    let cleanedValue = val
    // let cleanedValue = val.replace(/[^\w\s\d.,!?;:'"()\[\]{}\-\/\\]+/g, '')
    // .replace(/(\r?\n|\r){3,}/g, '\n\n');
    // .replace(/(\r?\n|\r){3,}/g, '\n\n');

    // Split the text by line breaks
    const lines = cleanedValue.split('\n');


    if (lines.length > 4) {
      cleanedValue.replace('\n', ' ');
    }

    return cleanedValue

    // let cleanedLines = [];
    // let lineBreakCount = 0;

    // for (let line of lines) {
    //   // If the line is not empty or we haven't exceeded the line break limit
    //   if (line.trim() !== '' || lineBreakCount < 2) {
    //     cleanedLines.push(line);
    //     if (line.trim() === '') {
    //       lineBreakCount++;
    //     }
    //   }
    // }


    // // Join the lines back together, ensuring no consecutive line breaks
    // cleanedValue = cleanedLines.join('\n').replace(/\n{2,}/g, '\n');

    // return cleanedValue; // Return the validated and cleaned text
  };




  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [textareaRef.current]);

  function countUnbrokenLines(text, charsPerLine) {
    const lines = text.split('\n');
    const lineCounts = [];
    lines.forEach(line => {
      // Calculate the number of lines this segment would occupy.
      const numLines = Math.ceil(line.length / charsPerLine);
      lineCounts.push(numLines);
    });
    if (lineCounts[lineCounts.length - 1] === 0) {
      lineCounts[lineCounts.length - 1] = 1
    }
    return lineCounts;
  }


  const removeSpaces = (val) => val.replace(/^\s+|\s+$/g, '')

  const processCaptions = (value, index) => {

    let newCaps = []

    if (value === '' || !value) {
      let newCaptions = captions.slice()
      newCaptions.splice(index, 1);
      console.log('newCaptions = ', newCaptions);
      newCaps = newCaptions.length ? newCaptions : [''];
    }

    if (value) {
      if (value.split('\n').length > 0 && value !== captions[0]) {
        const makeBrokenCaptions = (newValue) => {
          let newVal = value.split('\n');
          let newCaptions = captions.slice();
          newCaptions[index] = newVal;
          newCaptions = newCaptions.flat().map(oneCap => oneCap)
          return newCaptions;
        }
        newCaps = makeBrokenCaptions(value)
      } else {
        newCaps = captions.splice().map((one, ind) => index === ind && value)
        console.log('newCaps = ', newCaps);
      }
    }
    setCaptions(newCaps)

  }



  useEffect(() => {
    captions.forEach((one, index) => {
      if (!voice[index]) {
        const newArr = voice.slice()
        newArr[index] = newArr[0]
        setVoice(newArr)
      }
      if (!speed[index]) {
        const newArr = speed.slice()
        newArr[index] = newArr[0]
        setSpeed(newArr)
      }
      if (!background[index]) {
        const newArr = background.slice()
        newArr[index] = newArr[0]
        setBackground(newArr)
      }
    })
  }, [captions])


  const [move, setMove] = useState()
  const [newPop, setNewPop] = useState()
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (move) {
      // setOpacity(0)
      setTimeout(() => {
        setMove(0)
      }, 200);

      setTimeout(() => {
        context.handles.setPop(newPop);
        // setOpacity(1);
      }, 400);
    }
  }, [move])




  return (
    <>
      {oneTrack && <div className={shown1 ? "p0 active" : "p0"}>

        <div className="p_arr p_arr--prev" onClick={() => {
          setNewPop(context.handles.getPrevTrackId(oneTrackId))
          setMove(20)
          // context.handles.setPop(context.handles.getPrevTrackId(oneTrackId))
        }}>
          <div className="ico ico--100"><div>navigate_before</div></div>
        </div>

        <div className="p_arr" onClick={() => {
          setNewPop(context.handles.getNextTrackId(oneTrackId))
          // setNewPop(context.handles.setPop(context.handles.getNextTrackId(oneTrackId)))
          setMove(-20)
          // context.handles.setPop(context.handles.getNextTrackId(oneTrackId))
        }}>
          <div className="ico ico--100"><div>navigate_next</div></div>
        </div>



        <div className={shown2 ? "p_b active" : "p_b"}
          style={{
            transform: `translateX(${move}px)`,
            opacity: `${opacity}`,
          }}
        >
          <div className="p_b_top">
            <div className="p_b_title">
              <div className="ico">edit</div>
              Изменение перевода
            </div>
            <div className="p_b_x"
              onClick={() => handleClosePop()}
            >
              <div className="ico">
                <div>close</div>
              </div>
            </div>
          </div>
          <div className="p_b_c">


            <div className="p_b_player">

              <PopPlayer
                spaceBar={isFocused ? false : true}
                id={oneTrack['id']}
                // handleClick={() => {
                //   setLeftPadding(Math.floor(marginLeft / zoomLevel + 40))
                // }}
                handlePlayNext={() => {

                }}
                mp3={`${API_URL}/${oneTrack['file']}`}
              >
              </PopPlayer>

            </div>




            <div className="p_b_text">


              <div className="p_b_textarea00">



                {captions.map((one, index, arr) => {




                  return (<div
                    className="p_b_textarea0"
                    key={one.id}
                  >
                    {/* {breaks.map((one, ind, arr) => {
                  const tops = [

                  ]
                  return (<>
                    {ind !== arr.length - 1 && <Break key={ind} top={(one + ind) * 30} />}
                  </>)
                })} */}
                    <textarea
                      style={{
                        // width: 'auto',
                        minHeight: captions.length > 1 ? '7em' : '9em',
                      }}
                      cols={cols}
                      ref={textareaRef}
                      value={captions[index]}
                      onChange={(e) => processCaptions(e.target.value, index)}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      maxLength={5000}
                      id="field"
                      name="field"
                      data-name="Field"
                      className="p_b_textarea w-input"
                    />
                    <PopTools
                      handleVoice={(value, ind) => setVoice((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      handleSpeed={(value, ind) => setSpeed((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      handleBackground={(value, ind) => setBackground((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      voice={voice[index]}
                      speed={speed[index]}
                      background={background[index]}
                      index={index}
                    />
                  </div>)
                })}
              </div>

            </div>


            <div className="p_b_note">
              <div>☝️ Нажатие на Enter (разрыв строки) разобьет фрагмент на два</div>
            </div>
            <div className="p_b_bot">


              <div className="p_b_sub">
                <div className="x_delete"
                  onClick={() => context.handles.deleteFragment(oneTrack)}
                ><div className="ico"><div>delete_forever</div></div></div></div>



              {changed && !(context.state.waitingCut.includes(oneTrack.original_id)) && <div className="x_save x_save--cancel"
                onClick={() => handleReset()}
              >
                Сбросить все изменения
              </div>}


              {!(context.state.waitingCut.includes(oneTrack.original_id)) &&
                <button
                  className={changed ? "x_save" : "x_save x_save--cancel"}
                  style={{ opacity: changed ? 1 : 0.3 }}
                  // disabled={!changed}
                onClick={handleSubmit}
              >
                Применить
                </button>}

              {(context.state.waitingCut.includes(oneTrack.original_id)) && <div className={"x_save x_save--cancel"}
                style={{ cursor: 'disabled' }}
                onClick={handleSubmit}
              >
                Ждите...
              </div>}




            </div>
          </div>
          {context.state.waitingCut.includes(oneTrack.original_id) && <div className="p_b_preloader"><img src="https://assets-global.website-files.com/651f0451acd1cf1eb674532d/657223dc690f13a86f06e953_preloader.gif" loading="lazy" className="preloadergif" /></div>}
        </div>



        <div
          onClick={() => handleClosePop()}
          className={shown2 ? "p_z active" : "p_z"} />
      </div>}
    </>
  )
}





export function PopTools({
  speed = 1,
  voice = 'male',
  background = false,
  handleVoice,
  handleBackground,
  handleSpeed,
  index }) {

  const rounded = (number) => Math.floor(number * 10) / 10;

  return (
    <div className="p_b_tools">
      <div className="p_b_voice0">
        <div>Голос:</div>
        {/* <div className={voice === 'male' ? "p_b_voice active" : "p_b_voice"}
          onClick={() => handleVoice('male', index)}
        >
          <div>Олег Геннадиевич</div>
        </div>
        <div className={voice === 'female' ? "p_b_voice active" : "p_b_voice"}
          onClick={() => handleVoice('female', index)}
        >
          <div>Аудитория</div>
        </div> */}

        <select
          style={{ width: '180px' }}
          value={voice}
          onChange={(e) => {
            handleVoice(e.target.value, index)
          }}

        >
          <option value="male">Олег Торсунов</option>
          <option value="male1">Аудитория: Мужской</option>
          <option value="female">Аудитория: Женский</option>
          <option value="none">Без перевода</option>
        </select>



      </div>


      <div className="p_b_speed">
        <div>Скорость:</div>
        <HorizontalSlider
          value={speed * 100}
          min={60}
          max={120}
          width={200}
          // onValueChange={(val) =>
          //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
          // }
          onValueChange={(val) => handleSpeed(rounded(val / 100), index)}
        />
        <b>{speed}</b>
      </div>

      <div className="p_b_speed">
        <label>Звуковой фон: <input type='checkbox' checked={background} onChange={(e) => {
          console.log('e = ', e.target.checked);
          handleBackground(e.target.checked, index)
        }
        } /></label>

      </div>


    </div>
  )
}




export function Break({ top = 0 }) {
  return (
    <div
      style={{ top: top }}
      class="p_b_textarea_break inact">
      {/* <div>-----------------------------------------------</div> */}
      <div class="ico">content_cut</div>
      {/* <div>-----------------------------------------------</div> */}
    </div>
  )
}
